import React from 'react'
import { XyzThemeInterface, BrandThemeInterface } from '@postidigital/posti-theme'

export type XyzThemeColor = keyof XyzThemeInterface['color']
export type BrandThemeColor = keyof BrandThemeInterface['color']
export type BrandThemeFontWeight = keyof BrandThemeInterface['fontWeight']

/**
 * In e.g. Gatsby and other SSR rendering frameworks any code that expects window to be defined will break.
 * Use this function to access the window object.
 *
 * You can also optionally cast the Window type in order to extend it to a specific purpose or use a
 * fallback value to provide some window related functionality to the SSR context
 */
export const getWindow = <T = Window>(fallback: T = {} as T): T => {
  if (typeof window !== 'undefined') {
    return window as unknown as T
  }
  return fallback
}

export const animationParams = (duration: number, delay?: number, times?: Array<number>) => ({
  animate: { opacity: [0, 1], pathLength: [0, 1.1], scale: [1, 1] },
  transition: { duration: duration, ease: 'easeInOut', delay: delay ?? 0, times: times ?? [] },
})

export function isArray<T>(arg: unknown): arg is readonly T[] {
  return Array.isArray(arg)
}

type CallBack = (...args: any[]) => void
export const callAll =
  (...fns: Array<CallBack | undefined>) =>
  (...args: any[]) =>
    fns.forEach((fn) => typeof fn === 'function' && fn(...args))

export const enumToArray = (varEnum) =>
  Object.keys(varEnum).map(function (type) {
    return varEnum[type]
  })

/**
 * Generic function to set subcomponents for ref forwarded compoonent.
 * No casting so types will flow nicely
 */

export const addNamespace = <C extends React.ForwardRefExoticComponent<any>, NS extends object>(
  component: C,
  nameSpace: NS
): C & NS => {
  return Object.assign(component, nameSpace)
}
