import React from 'react'
import styled, { css, keyframes } from 'styled-components'
import { m } from 'framer-motion'
import PropTypes from 'prop-types'
import { animationParams } from '../../utils/helpers'

export interface AnimationProps {
  width?: string
  height?: string
  viewBox?: string
}

export const SVG = styled(m.svg)`
  fill: none;
`

const Path = styled(m.path)`
  ${({
    theme: {
      brand: { color },
    },
  }) => css`
    stroke: ${color.green80};
    stroke-width: 6px;
  `}
`

const CirclePath = styled(m.path)`
  ${({
    theme: {
      brand: { color },
    },
  }) => css`
    stroke: ${color.green80};
    stroke-width: 4px;
  `}
`

const bounce = keyframes`
  60% {
    transform: scale(1.00);
  }
  80% {
    transform: scale(1.05);
    animation-timing-function: ease-in-out;
  }
  100% {
    transform: scale(1);
  }
`

const SuccessDiv = styled.div`
  animation: ${bounce} 1500ms;
`
const circlePathParams = animationParams(1.2, 0, [0, 0.6])
const pathParams = animationParams(0.3, 0.6)

export const SuccessAnimation: React.FC<AnimationProps> = (props) => (
  <SuccessDiv>
    <SVG {...props}>
      <CirclePath
        d="M21.973 38.292c12.311-21.324 39.578-28.63 60.902-16.319 21.324 12.311 28.63 39.578 16.319 60.902-12.312 21.324-39.578 28.63-60.902 16.319-21.324-12.312-28.63-39.579-16.319-60.902Z"
        animate={circlePathParams.animate}
        transition={circlePathParams.transition}
      />
      <Path d="m40 60.5 13.5 13L80 47" animate={pathParams.animate} transition={pathParams.transition} />
    </SVG>
  </SuccessDiv>
)

export const animationDefaultProps = {
  width: `7.5rem`,
  height: `7.5rem`,
  viewBox: `0 0 120 120`,
}

export const animationPropTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  viewBox: PropTypes.string,
}

SuccessAnimation.defaultProps = animationDefaultProps
SuccessAnimation.propTypes = animationPropTypes
