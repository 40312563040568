import React from 'react'
import styled, { css } from 'styled-components'
import { m } from 'framer-motion'
import { AnimationProps, animationDefaultProps, animationPropTypes, SVG } from '../SuccessAnimation/SuccessAnimation'
import { animationParams } from '../../utils/helpers'

const BackgroundPath = styled(m.path)`
  ${({
    theme: {
      brand: { color },
    },
  }) => css`
    fill: ${color.yellow50};
  `}
`

const Path = styled(m.path)`
  ${({
    theme: {
      brand: { color },
    },
  }) => css`
    stroke: ${color.white};
    stroke-width: 6px;
  `}
`

export const backgroundPathParams = animationParams(0.55).transition
export const pathParams = animationParams(1.2).transition

export const WarningAnimation: React.FC<AnimationProps> = (props) => (
  <SVG {...props}>
    <BackgroundPath
      d="M56.1349 16.3108C58.4413 15.2853 61.0742 15.2853 63.3806 16.3108C65.1618 17.1028 66.3189 18.4979 67.1241 19.6347C67.9157 20.7523 68.7632 22.2165 69.6953 23.8267L69.7895 23.9894L106.553 87.4901C107.489 89.1064 108.339 90.5751 108.916 91.8216C109.502 93.0884 110.138 94.7912 109.935 96.7347C109.673 99.2496 108.355 101.535 106.31 103.022C104.73 104.171 102.938 104.474 101.548 104.602C100.18 104.727 98.4829 104.727 96.6152 104.727H22.9003C21.0326 104.727 19.3354 104.727 17.9678 104.602C16.5778 104.474 14.7856 104.171 13.2051 103.022C11.16 101.535 9.84241 99.2496 9.58022 96.7347C9.37759 94.7912 10.0134 93.0884 10.5994 91.8216C11.1761 90.5751 12.0265 89.1064 12.9624 87.4901L49.8201 23.8268C50.7522 22.2165 51.5998 20.7523 52.3914 19.6347C53.1966 18.4979 54.3537 17.1028 56.1349 16.3108Z"
      animate={{ scale: [0, 1.2, 1] }}
      transition={backgroundPathParams}
    />
    <Path
      d="M60 49V66.8333M60 84.6667H60.0446"
      strokeLinecap="round"
      animate={{ scale: [0, 1.2, 1] }}
      transition={pathParams}
    />
  </SVG>
)

WarningAnimation.defaultProps = animationDefaultProps
WarningAnimation.propTypes = animationPropTypes
