import React from 'react'
import styled, { css } from 'styled-components'
import { m } from 'framer-motion'
import { AnimationProps, animationDefaultProps, animationPropTypes, SVG } from '../SuccessAnimation/SuccessAnimation'

const Path = styled(m.path)`
  ${({
    theme: {
      brand: { color },
    },
  }) => css`
    stroke: ${color.gray30};
    stroke-width: 3;
    stroke-dasharray: 0.2, 12;
    stroke-linecap: round;
    stroke-linejoin: round;
    fill: none;
  `}
`

const Rect = styled.rect`
  fill: none;
`

export const ProgressAnimation: React.FC<AnimationProps> = (props) => (
  <SVG {...props}>
    <Rect />
    <Path
      d="M60.5834 105.167C35.9607 105.167 16.0001 85.206 16.0001 60.5833C16.0001 35.9606 35.9607 16 60.5834 16C85.2061 16 105.167 35.9606 105.167 60.5833C105.167 85.206 85.2061 105.167 60.5834 105.167Z"
      animate={{ rotate: 360 }}
      transition={{ duration: 5, repeat: Infinity, ease: 'linear' }}
    />
  </SVG>
)

ProgressAnimation.defaultProps = animationDefaultProps
ProgressAnimation.propTypes = animationPropTypes
