import React from 'react'
import styled, { css } from 'styled-components'
import { m } from 'framer-motion'
import { AnimationProps, animationDefaultProps, animationPropTypes, SVG } from '../SuccessAnimation/SuccessAnimation'
import { backgroundPathParams, pathParams } from '../WarningAnimation/WarningAnimation'

const BackgroundPath = styled(m.path)`
  ${({
    theme: {
      brand: { color },
    },
  }) => css`
    fill: ${color.red60};
  `}
`

const Path = styled(m.path)`
  ${({
    theme: {
      brand: { color },
    },
  }) => css`
    stroke: ${color.white};
    stroke-width: 6px;
  `}
`

export const ErrorAnimation: React.FC<AnimationProps> = (props) => (
  <SVG {...props}>
    <BackgroundPath
      d="M60.5 110C87.8381 110 110 87.8381 110 60.5C110 33.1619 87.8381 11 60.5 11C33.1619 11 11 33.1619 11 60.5C11 87.8381 33.1619 110 60.5 110Z"
      animate={{ scale: [0, 1.2, 1] }}
      transition={backgroundPathParams}
    />
    <Path
      d="M60.5833 42.75V60.5833M60.5833 78.4167H60.6279"
      strokeLinecap="round"
      animate={{ scale: [0, 1.2, 1] }}
      transition={pathParams}
    />
  </SVG>
)

ErrorAnimation.defaultProps = animationDefaultProps
ErrorAnimation.propTypes = animationPropTypes
